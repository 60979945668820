<template>

<!-- 档案管理页面  修改-->
  <div class="teacherManageAdd-container">
    <div class="teacjer-info">
      <el-form label-position="right" label-width="120px" ref="studentFromRef" :model="studentFrom" :rules="studentFromSref">
        <el-form-item label="学生名称" prop="name">
          <el-input maxlength="11" v-model="studentFrom.name"></el-input>
        </el-form-item>
        <el-form-item label="学校" prop="schoolId">
          <el-select v-model="studentFrom.schoolId" filterable placeholder="请选择" @change="delGreaClass">
            <el-option
              v-for="(item, index) in schoolList"
              :key="index"
              :label="item.schoolName"
              :value="item.id">
              <span style="float: left">{{ item.schoolName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.provinceName }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="年级" prop="gradeId">
          <el-select v-model="studentFrom.gradeId" placeholder="请选择" @change="delClass">
            <el-option
              v-for="(item, index) in AdministratorGetNewGradeData"
              :key="index"
              :label="item.gradeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="班级" prop="classId">
          <el-select v-model="studentFrom.classId" placeholder="请选择">
            <el-option
              v-for="(item, index) in getNewGradeClassData"
              :key="index"
              :label="item.className"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  label="学生状态" prop="schoolStatus">
          <el-radio-group v-model="studentFrom.schoolStatus">
            <el-radio :label="0">在读</el-radio>
            <el-radio :label="1">毕业</el-radio>
            <el-radio :label="2">离校</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="button-section">
        <el-button type="primary" @click="saveData">
        编辑
      </el-button>
      <router-link :to="{name: 'managementRecord'}">
        <el-button>
          取消
        </el-button>
      </router-link>
      </div>
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import studentService from '@/globals/service/management/student.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'
import { Loading } from 'element-ui'
export default {
  data () {
    return {
      getNewGradeClassData: [],
      AdministratorGetNewGradeData: [],
      schoolList: [],
      studentFromSref: {
        schoolId: [
          { required: true, message: '请选择学校', trigger: 'blur' }
        ],
        gradeId: [
          { required: true, message: '请选择年级', trigger: 'blur' }
        ],
        classId: [
          { required: true, message: '请选择班级', trigger: 'blur' }
        ],
        schoolStatus: [
          { required: true, message: '请选择学生状态', trigger: 'blur' }
        ]
      },
      studentFrom: {
        name: '',
        childrenId: null,
        schoolId: '',
        gradeId: '',
        classId: '',
        schoolStatus: null
      }
    }
  },
  created () {
    this.getData()
    this.Loading()
  },
  methods: {
    delClass () {
      this.studentFrom.classId = null
    },
    Loading () {
      const loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.9)'
      })
      return loadingInstance
    },
    delGreaClass () {
      this.studentFrom.gradeId = null
      this.studentFrom.classId = null
    },
    getClass () {
      if (!this.studentFrom.gradeId) return
      GradeClassService.getNewGradeClass({ newGradeId: this.studentFrom.gradeId, schoolId: this.studentFrom.schoolId, pageSize: 500, startPage: 1 }).then(res => {
        this.getNewGradeClassData = res.data.classVoList.map(item => {
          return {
            id: item.id,
            className: item.isClass
          }
        })
        const loadingInstance = this.Loading()

        loadingInstance.close()
      })
    },
    getData () {
      const data = this.$route.params.item
      for (const key in data) {
        this.studentFrom[key] = data[key]
        if (this.studentFrom[key] === '-') {
          this.studentFrom[key] = null
        }
      }
      if (this.studentFrom.birthday) this.studentFrom.birthday = (new Date(this.studentFrom.birthday)).getTime()
      this.studentFrom.name = data.childrenName + ''
      this.getSchool()
      this.studentFrom.schoolId = Number(data.schoolId)
      this.studentFrom.gradeId = Number(data.gradeId)
      this.studentFrom.classId = Number(data.classId)
      this.studentFrom.childrenId = Number(data.childrenId)
      switch (data.schoolStatus) {
        case '在读':
          this.studentFrom.schoolStatus = 0
          break
        case '毕业':
          this.studentFrom.schoolStatus = 1
          break
        case '离校':
          this.studentFrom.schoolStatus = 2
          break
      }
    },
    getGrade () {
      if (!this.studentFrom.schoolId) return
      AdministratorService.AdministratorGetNewGrade({ schoolId: this.studentFrom.schoolId }).then(res => {
        this.AdministratorGetNewGradeData = res.data.map(item => {
          return {
            id: item.id,
            gradeName: item.gradeName
          }
        })
        this.getClass()
      })
    },
    getSchool () {
      AdministratorService.AdministratorFindSchoolList().then(res => {
        this.schoolList = res.data.map(item => {
          return {
            id: item.id,
            schoolName: item.schoolName,
            provinceName: item.provinceName
          }
        })
        this.getGrade()
      })
    },
    saveData () {
      this.$refs.studentFromRef.validate(valid => {
        if (valid) {
          const data = this.studentFrom
          data.newGraderId = data.gradeId
          // data.school_status = data.schoolStatus
          studentService.editStudent(data).then(res => {
            this.$message({
              message: '编辑成功',
              type: 'success'
            })
            this.$router.push({ name: 'managementRecord' })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.teacherManageAdd-container{
  background-color: #fff;
  min-height: 100%;
  width: 100%;
  padding: 80px 40px 20px 61px;
  .teacjer-info{
    max-width: 500px;
    min-width: 400px;
    color: #333;
    .el-col-2{
      text-align: center;
    }
    .el-select{
      width: 100%;
    }
  }
  .button-section{
    .el-button{
      margin-left: 20px;
    }
  }
}
</style>
